<template>
    <div
        v-if="getUserRoles() == Admin || getUserRoles() == SuperAdmin"
        class="d-flex justify-content-end align-items-center"
    >
        <b-button variant="primary" class="mx-1" @click="edit">تعديل</b-button>
        <b-button variant="outline-primary" @click="$router.go(-1)"
            >تراجع
        </b-button>
        <b-button
            variant="outline-danger"
            class="ml-1"
            @click="deleteSub(Detailschapter.id)"
            >حذف</b-button
        >
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { getUserRoles } from "@/app/admin/auth/utils";
import { SuperAdmin, Admin } from "@/router";
export default {
    computed: {
        ...mapState({
            Detailschapter: (state) => state.chapter.Detailschapter,
        }),
    },
    data: () => ({
        SuperAdmin,
        Admin,
    }),
    methods: {
        ...mapActions(["updatechapter"]),
        getUserRoles,
        deleteSub(id) {
            this.$store.dispatch("deletechapter", [id]);
        },
        edit() {
            console.log("eidt");
            this.updatechapter({
                ...this.Detailschapter,
                id: this.$route.params.id,
            });
        },
    },
};
</script>
